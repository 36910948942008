import React from "react";

const SelectField = ({ label, name, id, options, register, errors }) => {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <select
        {...register(name)}
        className={`form-select ${errors[name] ? "is-invalid" : ""}`}
        id={id}
        aria-label={`Default select ${label}`}
      >
        <option value={""}>Select {label}</option>
        {options.map((option, index) => (
          <option key={index} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
      {errors[name] && (
        <div className="invalid-feedback d-block">{errors[name].message}</div>
      )}
    </div>
  );
};

export default SelectField;
