import React from "react";
import { Link } from "react-router-dom";

import bannerImage from "../img/success-banner-img.png";
import settings from "../config";
import useAuth from "../providers/useAuth";
import UseDocumentTitle from "../components/__Shared/UseDocumentTitle";

import { ReactComponent as Facebook } from "../img/icons/facebook.svg";
import { ReactComponent as XTwitter } from "../img/icons/x.svg";
import { ReactComponent as Linkedin } from "../img/icons/linkedin-blue.svg";
import { ReactComponent as Discord } from "../img/icons/discord-blue.svg";
import { ReactComponent as CheckCircle } from "../img/icons/check-green-circle.svg";
import { ReactComponent as ArrowRightCircle } from "../img/icons/arrow-right-circle-blue.svg";

export default function PaymentSuccess() {
  UseDocumentTitle("Payment Success");

  const purchaseStr = localStorage.getItem("purchase");
  const purchase = purchaseStr ? JSON.parse(purchaseStr) : null

  const amount = purchase?.amount;
  const leoAmount = purchase?.leoAmount;
  const currency = purchase?.currency;
  const usdAmount = Number(purchase?.usd);
  const receivedAmount = Number(purchase?.receivedAmount);

  const formatLEOSAmount = (value) => {
    const roundedValue = Math.round(value);
    return roundedValue.toLocaleString("en-US", {
      minimumFractionDigits: 0,
      maximumFractionDigits: 0,
    });
  };

  const formatAmount = (amount, fraction = 4) => {
    return parseFloat(amount).toLocaleString("en-US", {
      minimumFractionDigits: fraction,
      maximumFractionDigits: fraction,
    });
  };

  const twitterShareContent = encodeURIComponent(
    `🚀 Just bought ${formatLEOSAmount(
      leoAmount
    )} $LEOS and joined the future of digital governance with @PangeaWeb4! Excited to be part of a virtual nation that's redefining identity, security, and community. 🌐💡 \n#Web4 #VirtualNation #PangeaBlockchain`
  );

  const openShareWindow = (url) => {
    const width = 600;
    const height = 400;
    const left = window.screen.width / 2 - width / 2;
    const top = window.screen.height / 2 - height / 2;
    window.open(
      url,
      "ShareWindow",
      `width=${width},height=${height},top=${top},left=${left},toolbar=no,menubar=no,scrollbars=no,resizable=no,location=no,status=no`
    );
  };

  const { userData } = useAuth();

  return (
    <>
      <div className="payment-success-banner">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-lg-6 left-content text-center">
              <img className="img-fluid" src={bannerImage} alt="" />
            </div>
            <div className="col-lg-6 d-flex flex-column gap-4 right-content">
              <div className="top-content d-flex flex-column gap-4">
                <h2 className="heading">
                  Welcome to
                  <span className="Forest_Green_text"> Pangea</span>
                </h2>
                <div className="d-flex flex-column gap-2 description">
                  <p>
                    You have taken first step to support a social impact project
                    of a large scale.
                  </p>
                  <p>Feeling excited about Pangea and LEOS?</p>
                </div>
              </div>
              <div className="social-sharing-block">
                <div className="share-story-wrapper d-inline-block">
                  <p className="font-weight-bold">Share your story</p>
                  <div className="d-flex gap-2 mt-2">
                    <Link
                      onClick={(e) => {
                        e.preventDefault();
                        openShareWindow(
                          "https://www.facebook.com/sharer.php?u=https://pangea.web4.world"
                        );
                      }}
                      to="#"
                      className="social-item d-flex justify-center align-items-center"
                    >
                      <Facebook />
                    </Link>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        openShareWindow(
                          `https://twitter.com/intent/tweet?text=${twitterShareContent}`
                        );
                      }}
                      className="social-item d-flex justify-center align-items-center"
                    >
                      <XTwitter />
                    </Link>
                    <Link
                      to="#"
                      onClick={(e) => {
                        e.preventDefault();
                        openShareWindow(
                          "https://www.linkedin.com/sharing/share-offsite/?url=https://pangea.web4.world"
                        );
                      }}
                      className="social-item d-flex justify-center align-items-center"
                    >
                      <Linkedin />
                    </Link>
                  </div>
                </div>
                <div className="join-us-wrapper d-inline-block mt-4">
                  <p>Join us for more details</p>
                  <div className="d-flex gap-2 mt-2">
                    <Link
                      to="https://twitter.com/pangeaweb4"
                      target="_blank"
                      className="social-item d-flex justify-center align-items-center"
                    >
                      <XTwitter />
                    </Link>
                    <Link
                      to="https://www.linkedin.com/company/tonomy-foundation"
                      target="_blank"
                      className="social-item d-flex justify-center align-items-center"
                    >
                      <Linkedin />
                    </Link>
                    <Link
                      to="https://discord.gg/8zDf8AF3ja"
                      target="_blank"
                      className="social-item d-flex justify-center align-items-center"
                    >
                      <Discord />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container success-card-container">
        <div className="mobile-padding">
          <div className="row payment-success-wapper g-sm-5 g-4 align-items-center">
            <div className="caption-box d-flex flex-column gap-4">
              <div className="label-box d-flex flex-row align-items-center text-center gap-3">
                <div className="icon-bar">
                  <CheckCircle />
                </div>
                <h4 className="text-capitalize Forest_Green_text">
                  Payment successful
                </h4>
              </div>
              <div className="body-box d-flex flex-column gap-sm-4 gap-3">
                <div className="amount-listing d-flex flex-column m-0 gap-2">
                  <span className="label">Total Amount Paid</span>
                  <div className="price number-text d-flex gap-1">
                    <span className="eth-amount">{`${amount} ${currency}`}</span>
                    <span className="dollar-amount">
                      (${formatAmount(usdAmount, 2)})
                    </span>
                  </div>
                </div>
                <div className="text-caption d-flex flex-column gap-1">
                  <p>After your payment is processed</p>
                  <p>you will receive LEOS in your Pangea account:</p>
                  <div className="leo-amount">
                    <p className="leo-amount number-text">{`${formatAmount(
                      leoAmount
                    )}Ƚ`}</p>
                  </div>
                  {
                    receivedAmount > amount && <p>We have received extra funds. {formatAmount((receivedAmount - amount), 4)} ETH will be refunded to your account.</p>
                  }
                </div>
                <div className="btn-bar">
                  <Link
                    target="_blank"
                    to={`${settings.config.leosBalanceUrl}/account/${userData?.accName}`}
                    className="border-btn d-flex flex-grow-1 bg-tra allign-items-center gap-1"
                  >
                    <span>Check Here</span>
                    <div className="icon-bar">
                      <ArrowRightCircle />
                    </div>
                  </Link>
                </div>
              </div>
              <div className="share-purchase flex-md-row d-flex justify-content-between align-items-center text-white">
                <p>Share your purchase</p>
                <div className="d-flex gap-2">
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openShareWindow(
                        "https://www.facebook.com/sharer.php?u=https://pangea.web4.world"
                      );
                    }}
                    className="social-item d-flex justify-center align-items-center"
                  >
                    <Facebook />
                  </Link>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openShareWindow(
                        `https://twitter.com/intent/tweet?text=${twitterShareContent}`
                      );
                    }}
                    className="social-item d-flex justify-center align-items-center"
                  >
                    <XTwitter />
                  </Link>
                  <Link
                    to="#"
                    onClick={(e) => {
                      e.preventDefault();
                      openShareWindow(
                        "https://www.linkedin.com/sharing/share-offsite/?url=https://pangea.web4.world"
                      );
                    }}
                    className="social-item d-flex justify-center align-items-center"
                  >
                    <Linkedin />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
