import React, { useContext, useState } from "react";
import UseDocumentTitle from "../../../components/__Shared/UseDocumentTitle";
import { AuthContext } from "../../../providers/AuthProvider";
import getAuthorizedApi from "../../../providers/api";
import Modal from "../../../components/__Shared/Modal";
import { ExportToCSV } from "../../../utils/ExportToCSV";

import { ReactComponent as Download } from "../../../img/icons/download.svg";

export default function Dashboard() {
  UseDocumentTitle("Dashboard - LEOS Plateform");
  const { user } = useContext(AuthContext);
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  const handleExportData = async () => {
    try {
      setLoading(true);
      const response = await getAuthorizedApi(user).exportData();
      setLoading(false);
      const { columns, data } = response;
      ExportToCSV(columns, data, "Export Purchase Data");
    } catch (error) {
      setLoading(false);
      setShowError(true);
      setError(error.response.data.message);
    }
  };

  return (
    <div className="content-detail-block flex-grow-1">
      <Modal isOpen={showError} onClose={() => setShowError(false)}>
        <Modal.Header>
          <h3 className="model-title">Server Error</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="mt-2">{error}</p>
          <button
            onClick={() => setShowError(false)}
            className="btn btn-lg btn-secondary w-100 mt-4"
          >
            <span>Please try again</span>
          </button>
        </Modal.Body>
      </Modal>
      <div className="row">
        <div className="col-lg-4 col-sm-6 col-12">
          <button
            disabled={loading}
            onClick={handleExportData}
            className="btn btn-lg btn-light"
          >
            <Download />
            <span className="ms-2">Export Purchase Data</span>
          </button>
        </div>
      </div>
    </div>
  );
}
