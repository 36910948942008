import React from "react"

const TextareaField = ({ label, name, id, register, errors }) => {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <textarea
        {...register(name)}
        className={`form-control ${errors[name] ? "is-invalid" : ""}`}
        id={id}
      ></textarea>
      {errors[name] && (
        <div className="invalid-feedback d-block">{errors[name].message}</div>
      )}
    </div>
  )
}

export default TextareaField
