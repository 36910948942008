export const ExportToCSV = (columns, data, fileName) => {
  // Create CSV string from JSON data with semicolon delimiter
  const csvRows = []

  // Get headers
  csvRows.push(columns.join(";"))

  // Loop over the rows and format the values
  for (const row of data) {
    const values = columns.map((header) => {
      const value = row[header] || ""
      const escaped = ("" + value).replace(/"/g, '""')
      return `"${escaped}"`
    })
    csvRows.push(values.join(";"))
  }

  // Create a blob from the CSV string
  const csvString = csvRows.join("\n")
  const blob = new Blob([csvString], { type: "text/csv;charset=utf-8;" })

  // Create a link element to trigger the download
  const link = document.createElement("a")
  link.href = URL.createObjectURL(blob)
  link.setAttribute("download", `${fileName}.csv`)
  document.body.appendChild(link)
  link.click()
  document.body.removeChild(link)
}
