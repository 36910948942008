import React, { createContext, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import useAuth from "./useAuth";
import getAuthorizedApi from "./api";

export const AuthContext = createContext(null);

const AuthProvider = ({ children }) => {
  const { user, setUser, error, userData, loading } = useAuth();
  const { pathname } = useLocation();
  const navigation = useNavigate();

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  const signout = async () => {
    await user?.logout();
    navigation("/");
  };

  const signin = async (user, path = null) => {
    setUser(user);
    const response = await getAuthorizedApi(user).loginUser();
    if (response) {
      localStorage.setItem("purchaseId", response?.purchaseId);
      if (response?.verificationStatus === true) {
        path = "/onboarding/login";
      }
    }
    if (path) {
      navigation(path);
    }
  };

  const value = { user, signout, signin, userData, loading };

  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
