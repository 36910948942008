import React, { useState, useEffect } from "react"
import { Routes, Route, useLocation, Navigate } from "react-router-dom"
import Dashboard from "./Dashboard"
import Sidebar from "./__Shared/Sidebar"
import Topbar from "./__Shared/Topbar"
import useAuth from "../../providers/useAuth"
import settings from "../../config"
import Loading from "../../components/__Shared/Loading"
import AddPayment from "./AddPayment"

export default function Admin() {
  const { user, loading, userData } = useAuth()
  const location = useLocation()
  const [isActive, setActive] = useState(false)
  const [title, setTitle] = useState("Dashboard")
  function MenuBtn() {
    setActive(!isActive)
  }

  useEffect(() => {
    let newTitle = ""
    if (location.pathname === "/admin/add-payment") {
      newTitle = "Add Payment"
    } else {
      newTitle = "Dashboard"
    }
    setTitle(newTitle)
  }, [location])

  if (loading) {
    return <Loading />
  }

  if (
    user == null ||
    !settings.config.adminAccountName.includes(userData.accName)
  ) {
    return <Navigate to={"/"} replace={true} />
  }

  return (
    <div className="main-wapper-cpation d-flex">
      <div className={`sidebar-block h-100 ${isActive ? "active" : ""}`}>
        <Sidebar SidebarCloseBtn={MenuBtn} />
      </div>
      <div className="content-wapper d-flex flex-column flex-grow-1 w-100">
        <div className="top-bar-block">
          <Topbar SidebarMenuBtn={MenuBtn} PageHeading={title} />
        </div>
        <Routes>
          <Route path="/" Component={Dashboard} />
          <Route path="/add-payment" Component={AddPayment} />
        </Routes>
      </div>
    </div>
  )
}
