import React, { useContext } from "react";
import { AuthContext } from "../../../providers/AuthProvider";

import { ReactComponent as Menu } from "../../../img/icons/menu.svg";

export default function Topbar(props) {
  const { userName, signout } = useContext(AuthContext);

  return (
    <div className="top-bar-caption d-flex align-items-center justify-content-between">
      <div className="left-bar">
        <div className="left-bar-caption d-flex align-items-center gap-2">
          <button
            className="sidebar-menu-btn bg-transparent border-0"
            onClick={props.SidebarMenuBtn}
          >
            <div className="icon-bar">
              <Menu />
            </div>
          </button>
          <div className="heading">
            <h3>{props.PageHeading}</h3>
          </div>
        </div>
      </div>
      {userName && (
        <div className="admin-info-bar">
          <div className="dropdown">
            <button
              className="btn dropdown-toggle bg-white rounded-pill m-0 border-0"
              type="button"
              data-bs-toggle="dropdown"
              aria-expanded="false"
            >
              <span>{userName}</span>
            </button>
            <ul className="dropdown-menu border-0">
              <li>
                <button
                  className="dropdown-item"
                  onClick={() => {
                    signout();
                  }}
                >
                  Logout
                </button>
              </li>
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
