import React from "react";
import { Link } from "react-router-dom";

import { ReactComponent as Twitter } from "../../img/icons/twitter.svg";
import { ReactComponent as Linkedin } from "../../img/icons/linkedin.svg";
import { ReactComponent as Discord } from "../../img/icons/discord.svg";
import { ReactComponent as Github } from "../../img/icons/github.svg";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="footer-block">
        <div className="section-bg bg-black">
          <div className="footer-caption">
            <div className="footer-bot-bar">
              <div className="footer-bot-caption d-flex flex-column text-center justify-content-center align-items-center gap-4">
                <div className="d-flex align-items-center">
                  <p className="pe-3 border-end">Copyright © 2024 Pangea.</p>
                  <Link
                    to="https://pangea-web4-world.webflow.io/privacy-notice"
                    className="px-3 link-text"
                  >
                    Privacy Notice
                  </Link>
                </div>
                <p>Built by the Tonomy Foundation in Amsterdam, Netherlands</p>
                <ul className="d-flex align-items-center gap-2 list-unstyled m-0 p-0 social-media-bar">
                  <li>
                    <Link
                      to="https://twitter.com/pangeaweb4"
                      className="d-flex justify-content-center align-items-center rounded-circle bg-white text-black media-item"
                    >
                      <Twitter />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://www.linkedin.com/company/tonomy-foundation"
                      className="d-flex justify-content-center align-items-center rounded-circle bg-white text-black media-item"
                    >
                      <Linkedin />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://discord.gg/8zDf8AF3ja"
                      className="d-flex justify-content-center align-items-center rounded-circle bg-white text-black media-item"
                    >
                      <Discord />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://github.com/Tonomy-Foundation"
                      className="d-flex justify-content-center align-items-center rounded-circle bg-white text-black media-item"
                    >
                      <Github />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
