import { AuthenticationMessage, EosioUtil } from "@tonomy/tonomy-id-sdk";

import Comm from "../utils/Comm";

/**
 * Returns an object that can be used to make authorized requests to the server
 *
 * @param {User} user - the user object
 * @returns {Object} - the API object ready to send authorized requests to the server
 */
export default function getAuthorizedApi(user) {
  return {
    async loginUser() {
      const issuer = await user.getIssuer();
      const username = await user.getUsername();
      const shortUsername = username.getBaseUsername();
      const marketingEmailConsent = localStorage.getItem(
        "marketingEmailConsent"
      );
      const hasReadWhitepaper = localStorage.getItem("hasReadWhitepaper");
      const hearAboutUs = localStorage.getItem("hearAboutUs") || null;
      const referralCode = localStorage.getItem("referralCode") || null;

      const purchaseStr = localStorage.getItem("purchase");
      const purchase = purchaseStr ? JSON.parse(purchaseStr) : null

      const message = await AuthenticationMessage.signMessageWithoutRecipient(
        {
          username: shortUsername,
          emailMarketingConsent:
            marketingEmailConsent === "true" ? true : false,
          hasReadWhitepaper: hasReadWhitepaper === "true" ? true : false,
          amount: purchase?.amount,
          currency: purchase?.currency,
          referralCode,
          hearAboutUs,
        },
        issuer
      );

      const res = await Comm.post("/users/login", {
        message,
      });

      return res.data;
    },

    async getUserDetails() {
      const issuer = await user.getIssuer();
      const message = await AuthenticationMessage.signMessageWithoutRecipient(
        {},
        issuer
      );

      const res = await Comm.post("/users/getUserDetails", {
        message,
      });

      return res.data;
    },

    async getUsers(body) {
      const issuer = await user.getIssuer();
      const message = await AuthenticationMessage.signMessageWithoutRecipient(
        { ...body },
        issuer
      );
      const res = await Comm.post("/users", {
        message,
      });
      return res.data;
    },

    async purchaseAgreement(body) {
      const purchaseStr = localStorage.getItem("purchase");
      const purchase = purchaseStr ? JSON.parse(purchaseStr) : null
      let payload = {
        purchaseId: purchase?.purchaseId,
        ...body,
      };
      const issuer = await user.getIssuer();
      const message = await AuthenticationMessage.signMessageWithoutRecipient(
        { ...payload },
        issuer
      );
      const res = await Comm.post("/purchases/agreement", {
        message,
      });
      return res.data;
    },

    async purchase() {
      const purchaseStr = localStorage.getItem("purchase");
      const purchase = purchaseStr ? JSON.parse(purchaseStr) : null
      const purchaseId = purchase?.purchaseId;
      let payload = {
        purchaseId,
      };
      const issuer = await user.getIssuer();
      const message = await AuthenticationMessage.signMessageWithoutRecipient(
        { ...payload },
        issuer
      );
      const res = await Comm.post("/purchases/", {
        message,
      });
      return res.data;
    },

    async transaction(body) {
      const purchaseStr = localStorage.getItem("purchase");
      const purchase = purchaseStr ? JSON.parse(purchaseStr) : null
      const purchaseId = purchase?.purchaseId;
      let payload = {
        purchaseId,
        ...body,
      };
      const issuer = await user.getIssuer();
      const message = await AuthenticationMessage.signMessageWithoutRecipient(
        { ...payload },
        issuer
      );
      const res = await Comm.post("/purchases/transaction", {
        message,
      });
      return res.data;
    },

    async getVerificationStatus(body) {
      let payload = {
        ...body,
      };
      const issuer = await user.getIssuer();
      const message = await AuthenticationMessage.signMessageWithoutRecipient(
        { ...payload },
        issuer
      );
      const res = await Comm.post("/users/getVerificationStatus", {
        message,
      });
      return res.data;
    },

    async exportData() {
      const issuer = await user.getIssuer();
      const message = await AuthenticationMessage.signMessageWithoutRecipient(
        {},
        issuer
      );

      const res = await Comm.post("/admin/getPurchases", {
        message,
      });

      return res.data;
    },

    async addPayment(body) {
      let payload = {
        ...body,
      };
      const issuer = await user.getIssuer();
      const message = await AuthenticationMessage.signMessageWithoutRecipient(
        { ...payload },
        issuer
      );

      const res = await Comm.post("/admin/addPayment", {
        message,
      });

      return res.data;
    },

    async checkAccountName(accountName) {
      const account = await EosioUtil.getAccount(accountName);
      return account;
    },

    async createDeposit() {
      const purchaseStr = localStorage.getItem("purchase");
      const purchase = purchaseStr ? JSON.parse(purchaseStr) : null
      const purchaseId = purchase?.purchaseId;
      let payload = {
        purchaseId,
      };
      const issuer = await user.getIssuer();
      const message = await AuthenticationMessage.signMessageWithoutRecipient(
        { ...payload },
        issuer
      );
      const res = await Comm.post("/purchases/createDeposit", {
        message,
      });
      return res.data;
    },
  };
}
