import React from "react";

const InputField = ({
  label,
  name,
  id,
  type = "text",
  register,
  errors,
  placeholder = "",
}) => {
  return (
    <div>
      <label htmlFor={id} className="form-label">
        {label}
      </label>
      <input
        {...register(name)}
        type={type}
        placeholder={placeholder}
        className={`form-control ${errors[name] ? "is-invalid" : ""}`}
        id={id}
      />
      {errors[name] && (
        <div className="invalid-feedback d-block">{errors[name].message}</div>
      )}
    </div>
  );
};

export default InputField;
