import { ExternalUser, SdkError, SdkErrors } from "@tonomy/tonomy-id-sdk";
import React, { useContext, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../providers/AuthProvider";

const CallBack = () => {
  const { signin } = useContext(AuthContext);
  const navigation = useNavigate();
  const hasVerifiedLogin = useRef(false);

  useEffect(() => {
    const verifyLogin = async () => {
      try {
        const user = await ExternalUser.verifyLoginRequest();
        if (user) {
          signin(user, "/onboarding/login");
        }
      } catch (e) {
        if (e instanceof SdkError) {
          switch (e.code) {
            case SdkErrors.UserCancelled:
              navigation("/");
              break;
            case SdkErrors.UserLogout:
              navigation("/");
              break;
            case SdkErrors.UserRefreshed:
              navigation("/");
              break;
            default:
              console.error(e);
              break;
          }
        } else {
          console.error(e);
        }
      }
    };

    if (!hasVerifiedLogin.current) {
      hasVerifiedLogin.current = true;
      verifyLogin();
    }
  }, [signin, navigation]);

  return (
    <div
      className="position-relative container px-0"
      style={{ minHeight: "100vh" }}
    >
      <div className="loader-container">
        <span className="loader"></span>
      </div>
    </div>
  );
};

export default CallBack;
