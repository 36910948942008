import { ReactComponent as ArrowLeft } from "../../img/icons/arrow-left.svg";
import { ReactComponent as ArrowRight } from "../../img/icons/arrow-right.svg";

const OnboardingNextPrevButton = ({
  onNext = () => {},
  onPrevious = () => {},
  disabledNext = false,
  disabledPrev = false,
  hideNext = false,
  hidePrev = false,
}) => {
  return (
    <>
      {!hidePrev && (
        <button
          disabled={disabledPrev}
          className="prev-btn"
          onClick={onPrevious}
        >
          <ArrowLeft className="me-1" />
          Back
        </button>
      )}
      {!hideNext && (
        <button disabled={disabledNext} className="next-btn" onClick={onNext}>
          Next
          <ArrowRight />
        </button>
      )}
    </>
  );
};
export default OnboardingNextPrevButton;
