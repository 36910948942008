import React, { useEffect, useState } from "react";
import { Veriff } from "@veriff/js-sdk";
import { createVeriffFrame, MESSAGES } from "@veriff/incontext-sdk";
import { useNavigate } from "react-router-dom";
import settings from "../../../config";
import getAuthorizedApi from "../../../providers/api";
import Modal from "../../__Shared/Modal";

const VeriffRoot = ({ user = null, userData = null, confirm }) => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [showError, setShowError] = useState(false);
  const [showCountryError, setShowCountryError] = useState(false);

  useEffect(() => {
    let veriffId = null;
    const veriff = Veriff({
      host: "https://stationapi.veriff.com",
      apiKey: settings.config.veriffApi,
      parentId: "veriff-root",
      onSession: handleVeriffSession,
    });

    async function handleVeriffSession(err, response) {
      if (err) {
        console.error("Veriff session error:", err);
        return;
      }

      veriffId = response.verification.id;
      let verification_url = response.verification.url;

      createVeriffFrame({
        url: verification_url,
        onEvent: handleVeriffEvent,
      });
    }

    async function checkVerificationStatus() {
      if (veriffId) {
        try {
          setLoading(true);
          const res = await getAuthorizedApi(user).getVerificationStatus({
            id: veriffId,
          });
          setLoading(false);
          if (res.status === "approved" && res.eligibleCountry) {
            //navigation("/onboarding/token-sale-agreement");
            setLoading(false);
            confirm()
          } else {
            if (res.status === "approved" && res.eligibleCountry === false) {
              setShowCountryError(true);
              setShowError(false);
            } else if (res.status === "resubmission_requested") {
              setError(
                `There was an issue scanning your documents. Please try again for resubmission. ${res.reason}`
              );
              setShowError(true);
            } else if (res.status === "expired") {
              setError("Your session has expired. Please try again.");
              setShowError(true);
            } else if (res.status === "declined") {
              setError(
                `Your Veriff verification has been declined.\n${res.reason}`
              );
              setShowError(true);
            }
          }
        } catch (e) {
          let message = e.response.data.reason;
          if (e?.response?.data?.message) {
            message = e?.response?.data?.message;
          }
          setError(message);
          setLoading(false);
          setShowError(true);
          setShowCountryError(false);
        }
      }
    }

    async function handleVeriffEvent(msg) {
      switch (msg) {
        case MESSAGES.STARTED:
          console.log("User started verification");
          break;
        case MESSAGES.CANCELED:
          console.log("User canceled verification");
          break;
        case MESSAGES.FINISHED:
          console.log("User completed verification");
          checkVerificationStatus();
          break;
        default:
          break;
      }
    }

    veriff.setParams({
      person: {
        givenName: " ",
        lastName: " ",
      },
      vendorData: userData?.accName,
    });

    veriff.mount({
      submitBtnText: "Identity Verification with veriff.com",
      loadingText: "Please wait...",
    });
  }, [navigation, userData, user]);

  return (
    <div>
      <Modal isOpen={showError} onClose={() => setShowError(false)}>
        <Modal.Header>
          <h3 className="model-title">Verification Failed</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-4 fs-6 veriff-error">{error}</p>
          <button
            onClick={() => setShowError(false)}
            className="btn btn-lg btn-secondary w-100"
          >
            <span>Please try again</span>
          </button>
        </Modal.Body>
      </Modal>
      <Modal
        isOpen={showCountryError}
        onClose={() => setShowCountryError(true)}
      >
        <Modal.Header>
          <h3 className="model-title">Unable to processed</h3>
        </Modal.Header>
        <Modal.Body>
          <p>
            Please submit a verification document from a supported country in
            the EU.
            <br /> We are working REALLY hard to compliant sales to LEOS
            customers in other countries.
          </p>
          <button
            onClick={() => setShowCountryError(false)}
            className="btn btn-lg btn-secondary w-100 mt-4"
          >
            <span>Please try again</span>
          </button>
        </Modal.Body>
      </Modal>

      <div className={loading ? "d-none" : "d-block"}>
        <div id="veriff-root"></div>
      </div>
      {loading && (
        <div className="loader-container">
          <span className="loader"></span>
          <span className="text">
            Please wait... <br />
            Verifying you identity
          </span>
        </div>
      )}
    </div>
  );
};

export default VeriffRoot;
