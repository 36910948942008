import axios from "axios"
import settings from "../config"

axios.defaults.crossDomain = true

export const DefaultBaseURL = (() => {
  return settings.config.backendUrl
})()

const Comm = axios.create({
  baseURL: DefaultBaseURL,
  timeout: 600000, // value in milliseconds
  // headers: {
  //   Accept: "application/json",
  // },
})

export default Comm
