import { useState, useEffect } from "react"
import { ExternalUser, SdkError, SdkErrors } from "@tonomy/tonomy-id-sdk"
const useAuth = () => {
  const [user, setUser] = useState(null)
  const [error, setError] = useState(null)
  const [loading, setLoading] = useState(true)
  const [userData, setUserData] = useState(null)
  useEffect(() => {
    async function authentication() {
      setLoading(true)
      try {
        const user = await ExternalUser.getUser({ autoLogout: false })

        if (user) {
          const username = await user?.getUsername()
          const accName = await user?.getAccountName()
          let baseUsername = ""
          if (username) {
            baseUsername = username.getBaseUsername()
          }
          setUserData({
            username,
            accName,
            baseUsername,
          })
          setUser(user)
          setError(null)
        }
        setLoading(false)
      } catch (e) {
        if (
          e instanceof SdkError &&
          (e.code === SdkErrors.AccountNotFound ||
            e.code === SdkErrors.AccountDoesntExist ||
            e.code === SdkErrors.UserNotLoggedIn)
        ) {
          // User not logged in
          setUser(null)
          setLoading(false)
          setError("UserNotLoggedIn")
          return
        }
        setLoading(false)
        // errorStore.setError({ error: e, expected: false });
      }
    }
    authentication()
  }, [])

  return { user, setUser, error, loading, userData }
}

export default useAuth
