import React, { useEffect } from "react";

import UseDocumentTitle from "../components/__Shared/UseDocumentTitle";
import settings from "../config";

export default function Home() {
  UseDocumentTitle("LEOS Sales Plateform - Pangea");

  useEffect(() => {
    let path = settings.config.landingpageUrl;
    window.location.replace(path);
  });

  return <></>;
}
