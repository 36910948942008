import React, { useEffect } from "react";

const Modal = ({ isOpen, onClose, children, large = null }) => {
  const modalClass = isOpen
    ? "modal show myModal d-block"
    : "modal fade myModal d-none";

  const sizeClass = large
    ? "modal-dialog modal-dialog-centered modal-lg"
    : "modal-dialog modal-dialog-centered ";

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }
    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  return (
    <div
      data-bs-backdrop="static"
      data-bs-keyboard="false"
      className={modalClass}
      id="modalToggle"
      aria-hidden={!isOpen}
      aria-labelledby="modalToggleLabel"
      tabIndex="-1"
    >
      <div className={sizeClass}>
        <div className="modal-content shadow-lg bg-white">{children}</div>
      </div>
    </div>
  );
};

Modal.Header = ({ children }) => {
  return <div className="modal-header">{children}</div>;
};

Modal.Body = ({ children, header = true }) => {
  const classNames = !header ? "modal-body pad-top" : "modal-body";
  return <div className={classNames}>{children}</div>;
};

Modal.Footer = ({ children }) => {
  return <div className="modal-footer">{children}</div>;
};

export default Modal;
