import React from "react";
import { Link } from "react-router-dom";

// <---------- section component start ---------->
import SidebarMenu from "./SidebarMenu";
// <---------- section component end ---------->

// <-------------- img start --------------->
import logo from "../../../img/logo.png";
import { ReactComponent as Close } from "../../../img/icons/close.svg";

// <-------------- img end --------------->

// <------- SidebbarMenu Data start ---------->
const SidebbarMenuData = [
  {
    id: 1,
    MenuHeading: "dashbboard",
    MenuUrl: "/admin",
  },
  {
    id: 2,
    MenuHeading: "Add Payment",
    MenuUrl: "/admin/add-payment",
  },
];
// <------- SidebbarMenu Data end ---------->

export default function Sidebar(props) {
  return (
    <div className="sidebar-caption d-flex flex-column gap-4 h-100">
      <div className="sidebar-top-box d-flex align-items-center gap-lg-2 gap-4">
        <div className="logo-bar">
          <Link to="">
            <img src={logo} alt="" />
          </Link>
        </div>
        <button
          className="sidebar-close-btn bg-transparent border-0"
          onClick={props.SidebarCloseBtn}
        >
          <div className="icon-bar">
            <Close />
          </div>
        </button>
      </div>
      <div className="menu-bar flex-grow-1">
        <ul className="menu-bar-box d-flex flex-column list-unstyled p-0 m-0">
          {SidebbarMenuData.map((element) => {
            return (
              <li className="menu-bar-item" key={element.id}>
                <SidebarMenu
                  MenuTitle={element.MenuHeading}
                  MenuLink={element.MenuUrl}
                />
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
}
