import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DocusealForm } from "@docuseal/react";
import moment from "moment";
import settings from "../../../config";
import getAuthorizedApi from "../../../providers/api";
import Modal from "../../__Shared/Modal";

const Docuseal = ({ user = null, name = "", idNumber = null, onComplete }) => {
  const navigation = useNavigate();
  const [loading, setLoading] = useState(true);
  const todayDate = moment().format("YYYY-MM-DD");

  const purchaseStr = localStorage.getItem("purchase");
  const purchase = purchaseStr ? JSON.parse(purchaseStr) : null

  const deadlinDate = moment().add(12, "months").format("YYYY-MM-DD");

  const [accountName, setAccountName] = useState("");
  const [showError, setShowError] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchUsername = async () => {
      if (user) {
        const accountName = await user.getAccountName();
        setAccountName(accountName);
      }
    };
    fetchUsername();
    const timer = setTimeout(() => {
      setLoading(false);
    }, 1500);
    return () => clearTimeout(timer);
  }, [user]);
  let valuse = {
    Currency: purchase?.currency,
    "Deadline date": deadlinDate,
    Amount: purchase?.amount,
    "Date 1": todayDate,
    Name: name,
    "Identity Number": idNumber, // get from veriff
    "Date 2": todayDate,
  };
  let readonlyFields = [
    "Currency",
    "Deadline date",
    "Amount",
    "Date 1",
    "Name",
    "Identity Number",
    "Date 2",
  ];

  const signComplete = async (data) => {
    if (data.status === "completed") {
      try {
        setLoading(true);
        await getAuthorizedApi(user).purchaseAgreement({
          docusealId: data.id,
          submissionId: data.submission_id,
        });
        onComplete(true);
        setLoading(false);
      } catch (err) {
        setLoading(false);
        setShowError(true);
        if (err.response.data.status === 500) {
          setError(
            "There was an internal server error. Please reach out to our support team for assistance."
          );
        } else {
          onComplete(false, err.response.data.reason);
          setError(err.response.data.reason);
        }
      }
    }
  };

  const css = "#scrollbox { height: calc(100vh - 250px); overflow: auto; }";

  return (
    <div className="position-relative border pb-2">
      <Modal isOpen={showError} onClose={() => setShowError(false)}>
        <Modal.Header>
          <h3 className="model-title">Document Signing Failed</h3>
        </Modal.Header>
        <Modal.Body>
          <p className="mb-3">{error}</p>
          <button
            onClick={() => {
              setShowError(false);
              navigation("/");
            }}
            className="btn btn-lg btn-secondary w-100"
          >
            <span>Please try again</span>
          </button>
        </Modal.Body>
      </Modal>
      <DocusealForm
        src={settings.config.docusealUrl}
        values={valuse}
        readonlyFields={readonlyFields}
        onComplete={signComplete}
        autoscrollFields={false}
        externalId={accountName} // united wallet account name for referance
        customCss={css}
        expand={false}
      />

      {loading && (
        <div className="loader-container">
          <span className="loader"></span>
        </div>
      )}
    </div>
  );
};

export default Docuseal;
