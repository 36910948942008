import React, { useEffect, useState } from "react";
import { Routes, Route } from "react-router-dom";
import { useLocation, useNavigate } from "react-router-dom";
import { ExternalUser } from "@tonomy/tonomy-id-sdk";

// <------- section components start --------->
import StepStructure from "../components/BuyLEOS_Step/__Shared/StepStructure";
import PreparationStep from "../components/BuyLEOS_Step/PreparationStep";
import LoginStep from "../components/BuyLEOS_Step/LoginStep";
import IdentityVerificationStep from "../components/BuyLEOS_Step/IdentityVerificationStep";
import TokenAgreementStep from "../components/BuyLEOS_Step/TokenAgreementStep";
import PaymentStep from "../components/BuyLEOS_Step/PaymentStep";
import UseDocumentTitle from "../components/__Shared/UseDocumentTitle";
import CallBack from "./CallBack";

// <------- section components end --------->

export default function BuyLEOSStep({ location }) {
  UseDocumentTitle("Buy LEOS Step");
  const navigate = useNavigate();
  const path = useLocation();

  async function onButtonPress() {
    await ExternalUser.loginWithTonomy({
      callbackPath: "/callback",
      dataRequest: { username: true },
    });
  }

  var [activeStep, setActiveStep] = useState(0);

  useEffect(() => {
    if (path.pathname === "/onboarding/start") {
      setActiveStep(0);
    } else if (path.pathname === "/onboarding/login") {
      setActiveStep(1);
    } else if (path.pathname === "/onboarding/identity-verification") {
      setActiveStep(2);
    } else if (path.pathname === "/onboarding/token-sale-agreement") {
      setActiveStep(3);
    } else {
      setActiveStep(4);
    }
  }, [path]);

  useEffect(() => {
    const queryString = window.location.search;
    const query = new URLSearchParams(queryString);
    const urlAmount = query.get("amount");
    const urlUsdAmount = query.get("usdAmount");
    const urlLeosAmount = query.get("leos");
    const urlCurrency = query.get("currency") || "ETH";
    const referralCode = query.get("code") || null;

    if (
      urlAmount !== "" &&
      urlAmount !== null &&
      urlCurrency !== "" &&
      urlCurrency !== null &&
      urlUsdAmount !== "" &&
      urlUsdAmount !== null &&
      urlLeosAmount !== "" &&
      urlLeosAmount !== null
    ) {

      const purchaseData = {
        amount: urlAmount,
        usd: urlUsdAmount,
        currency: urlCurrency,
        leosAmount: urlLeosAmount
      };

      localStorage.setItem("purchase", JSON.stringify(purchaseData));

      if (referralCode && referralCode !== "null")
        localStorage.setItem("referralCode", referralCode);

      navigate("/onboarding/start", { replace: true });
    }
  }, [navigate]);

  var steps = [
    { label: "Start" },
    { label: "Login" },
    { label: "Identity Verification" },
    { label: "Token sale agreement" },
    { label: "Payment" },
  ];

  return (
    <>
      <div className="stepform-wapper">
        <div className="container">
          <div className="stepform-block d-flex flex-column">
            <div className="stepform-progress">
              <StepStructure steps={steps} activeStep={activeStep} />
            </div>
            <div className="stepform-content d-flex flex-column justify-content-center flex-grow-1 two-space">
              <div className="step-form-content-caption">
                <Routes>
                  <Route path="/start" Component={PreparationStep} />
                  <Route
                    path="/login"
                    element={<LoginStep onButtonPress={onButtonPress} />}
                  />
                  <Route path="/callback" Component={CallBack} />
                  <Route
                    path="/identity-verification"
                    Component={IdentityVerificationStep}
                  />
                  <Route
                    path="/token-sale-agreement"
                    Component={TokenAgreementStep}
                  />
                  <Route path="/payment" Component={PaymentStep} />
                </Routes>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
