import React, { useContext, useEffect, useState } from "react";
import MetamaskIcon from "../../../img/metamask-icon.png";
import Modal from "../../__Shared/Modal";
import { ethers } from "ethers";
import { useNavigate } from "react-router-dom";
import settings from "../../../config";

import getAuthorizedApi from "../../../providers/api";
import Comm from "../../../utils/Comm";
import { useSDK } from "@metamask/sdk-react-ui";
import { AuthContext } from "../../../providers/AuthProvider";

const Metamask = ({ amount, currency, onComplete }) => {
  const { user } = useContext(AuthContext);
  const { sdk, connected, connecting, account } = useSDK();

  const [responseMessage, setResponseMessage] = useState(null);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [transactionProcessing, setTransactionProcessing] = useState(false);
  const [transactionAmount, setTransactionAmount] = useState(null);

  const purchaseStr = localStorage.getItem("purchase");
  const purchase = purchaseStr ? JSON.parse(purchaseStr) : null

  let navigate = useNavigate();

  useEffect(() => {
    let ethConfig = {
      method: "get",
      url: `/crypto?symbol=ETH`,
      headers: {},
    };
    Comm.request(ethConfig)
      .then((response) => {
        if (currency !== "ETH") {
          const usdAmount = Number(purchase?.usd);
          setTransactionAmount(usdAmount / Number(response.data.usd));
        } else {
          setTransactionAmount(Number(amount));
        }
      })
      .catch((error) => {
        //console.log(error)
      });
  }, [amount, currency, purchase]);

  const connectWallet = async () => {
    try {
      const activeProvider = sdk?.getProvider();
      await activeProvider.request({
        method: "eth_requestAccounts",
      });
    } catch (e) {
      console.log(e);
      resetStates();
    }
  };

  const disconnectWallet = async () => {
    sdk.terminate();
  };

  const sendTransaction = async (e) => {
    e.preventDefault();
    if (transactionAmount) {
      if (settings.config.metamaskAccountId === "") {
        alert("Error: Metamask configuration is missing");
        return;
      }
      setTransactionProcessing(true);
      const etherAmount = transactionAmount.toFixed(6);

      const weiAmount = ethers.parseEther(etherAmount.toString());
      const params = [
        {
          from: account,
          to: settings.config.metamaskAccountId,
          value: Number(weiAmount).toString(16),
        },
      ];

      const activeProvider = sdk?.getProvider();

      const transactionHash = await activeProvider
        .request({ method: "eth_sendTransaction", params })
        .catch(async (err) => {
          setResponseMessage(err.message);
          setError(true);
        });

      if (transactionHash) {
        try {
          const response = await getAuthorizedApi(user).transaction({
            paymentMethod: "metamask",
            transactionId: transactionHash,
            timezone: Intl.DateTimeFormat().resolvedOptions().timeZone || "UTC",
          });

          setSuccess(true);

          fetchTransactionReceipt("success", response.leos, response.usd);
          setResponseMessage("Payment Successfully Transferred!");
        } catch (error) {
          let message = error.response?.data?.reason;
          if (error.response?.data?.message) {
            message = error.response?.data?.message;
          }
          if (error.response.data.status === 500) {
            message = "There was an internal server error.";
          }
          setResponseMessage(
            `${message}. Please reach out to our support team for assistance.`
          );
          setError(true);
        }
      }
    }
  };

  const fetchTransactionReceipt = async (
    status,
    leos = purchase?.leosAmount,
    usd = purchase?.usd
  ) => {
    purchase.leoAmount = leos
    purchase.usd = usd
    delete purchase.purchaseID
    delete purchase.leosAmount
    localStorage.setItem("purchase", JSON.stringify(purchase));
    setSuccess(true);
    setTransactionProcessing(false);
    resetStates();
    if (status === "success") {
      navigate("/payment-success");
    }
  };

  const closeModal = () => {
    resetStates();
  };

  const resetStates = () => {
    setResponseMessage(null);
    setTransactionProcessing(false);
    setError(false);
    onComplete();
  };

  let roundedCircleIconClass = "";
  if (transactionProcessing) {
    roundedCircleIconClass = "circle-animation";
  }
  if (isError === true) {
    roundedCircleIconClass = "border border-danger border-3";
  }
  if (isSuccess === true) {
    roundedCircleIconClass = "border border-success border-3";
  }
  return (
    <div>
      {!connected && (
        <div>
          <button
            onClick={connectWallet}
            className="metamask-btn d-flex gap-2 w-100"
          >
            <img src={MetamaskIcon} height={30} alt="" />
            Connect Metamask
          </button>
        </div>
      )}

      {/* boostrap modal */}
      <Modal isOpen={transactionProcessing} onClose={closeModal}>
        <Modal.Body>
          <div className="modal-body d-flex flex-column justify-content-center align-items-center gap-3 mt-5">
            <div
              className={`bg-white p-4 rounded-circle ${roundedCircleIconClass}`}
            >
              <img src={MetamaskIcon} height={60} alt="" />
            </div>

            <h6 className="text-dark">
              {responseMessage && isError
                ? "Payment failed!"
                : isSuccess
                  ? "Payment Success"
                  : "Payment in process."}
            </h6>
            <p className="text-center">
              {responseMessage
                ? responseMessage
                : "Your transaction is currently being processed. Please wait for confirmation."}
            </p>
          </div>
        </Modal.Body>
        {transactionProcessing && responseMessage && (
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-primary"
              onClick={closeModal}
            >
              Close
            </button>
          </Modal.Footer>
        )}
      </Modal>

      {connecting && responseMessage ? (
        <div className={"d-flex"}>
          <button
            className="w-100 metamask-btn"
            type="button"
            onClick={closeModal}
          >
            <span className="sr-only ml-2">{responseMessage}</span>
          </button>
        </div>
      ) : (
        <div className="d-none"></div>
      )}

      {connected && !transactionProcessing && (
        <div className="d-flex flex-column gap-4">
          <div className="crypto-connected-account">
            <div className="d-flex justify-content-end ">
              <button onClick={disconnectWallet} className="btn btn-link p-0">
                Disconnect Wallet
              </button>
            </div>
            <ul className="connected-account d-flex flex-column m-0 p-0">
              <li className="d-flex justify-content-between align-items-center">
                <span className="label">Connected Account</span>
                <p className="account-id">{account}</p>
              </li>
            </ul>
          </div>
          <div className={"payment-btn d-block"}>
            <button
              disabled={!transactionAmount}
              onClick={sendTransaction}
              className="btn btn-lg py-3 btn-primary d-flex gap-2 w-100 justify-content-center align-items-center"
            >
              <img src={MetamaskIcon} height={30} alt="" />
              Pay with Metamask
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default Metamask;
