import React from 'react';
import { Link, useLocation  } from 'react-router-dom';


export default function SidebarMenu(props) {

  const location = useLocation();
  const { pathname } = location;

  return (

      <Link to={props.MenuLink} className={`menu-link ${ pathname === props.MenuLink ? 'active': ''}`}>{props.MenuTitle}</Link>
  )
}
