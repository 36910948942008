import logo from "../../img/logo.png"

const Loading = () => {
  return (
    <div className="container-fluid vh-100 loading-screen">
      <div className="d-flex justify-content-center align-items-center h-100">
        <div className="d-flex flex-column justify-content-center text-center">
          <img src={logo} alt="" />
          <span>Loading...</span>
        </div>
      </div>
    </div>
  )
}
export default Loading
