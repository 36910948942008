import React, { useEffect, useState } from "react";
import { Routes, Route, useMatch, Navigate } from "react-router-dom";
import { setFetch, setSettings } from "@tonomy/tonomy-id-sdk";

// <----- asset start ----------->
// ----css start---->
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import "../src/css/style.css";
import "../src/css/media_query.css";
// ----css end---->

// ----js start---->
import "../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js";
// ----js end---->
// <----- asset end ----------->

// <----- section components start ----------->
import Home from "./pages/Home";
import BuyLEOSStep from "./pages/BuyLEOSStep";
import CallBack from "./pages/CallBack.js";
import Header from "./components/__Shared/Header.js";
import Footer from "./components/__Shared/Footer.js";
import AuthProvider from "./providers/AuthProvider.js";
import { useLocation } from "react-router-dom";
import Admin from "./pages/Admin/index.js";
import settings from "./config.js";
import PaymentSuccess from "./pages/PaymentSuccess.js";

// <----- section components end ----------->

export default function App() {
  const isEmpty = !!useMatch("/onboarding/");
  const [showLayout, setShowLayout] = useState(true);
  const { pathname } = useLocation();
  const isAdmin = !!useMatch("/admin/*");

  useEffect(() => {
    if (pathname === "/callback" || isAdmin) {
      setShowLayout(false);
    } else {
      setShowLayout(true);
    }
  }, [pathname, isAdmin]);

  useEffect(() => {
    const queryString = window.location.search;
    const query = new URLSearchParams(queryString);
    const referralCode = query.get("code") || null;
    if (referralCode && referralCode !== "null")
      localStorage.setItem("referralCode", referralCode);
  }, []);

  setSettings({
    ssoWebsiteOrigin: settings.config.ssoWebsiteOrigin,
    blockchainUrl: settings.config.blockchainUrl,
    communicationUrl: settings.config.communicationUrl,
  });
  setFetch(window.fetch.bind(window));

  if (isEmpty) {
    return <Navigate to={"/onboarding/start"} replace={true} />;
  }

  return (
    <AuthProvider>
      <div className="main-wapper home-page-wapper">
        {showLayout && <Header />}
        <Routes>
          <Route path="/" Component={Home} />
          <Route path="/onboarding/*" Component={BuyLEOSStep} />
          <Route path="/admin/*" Component={Admin} />
          <Route path="/callback" Component={CallBack} />
          <Route path="/payment-success" Component={PaymentSuccess} />
        </Routes>
        {showLayout && <Footer />}
      </div>
    </AuthProvider>
  );
}
