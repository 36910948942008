import React from "react"

const RadioField = ({ label, name, id, options, register, errors }) => {
  return (
    <div className="mb-3">
      <label htmlFor={id} className="form-label d-block">
        {label}
      </label>
      {options.map((option, index) => (
        <div key={index} className="form-check form-check-inline">
          <input
            {...register(name)}
            className="form-check-input"
            type="radio"
            id={`${id}_${option.value}`}
            value={option.value}
          />
          <label className="form-check-label" htmlFor={`${id}_${option.value}`}>
            {option.label}
          </label>
        </div>
      ))}
      {errors[name] && (
        <div className="invalid-feedback d-block">{errors[name].message}</div>
      )}
    </div>
  )
}

export default RadioField
